$color-base: (
  1: var(--color-base-1, transparent),
  2: var(--color-base-2, transparent),
  4: var(--color-base-4, transparent),
);

$color-accents: (
  1: var(--color-accent-1, transparent),
  2: var(--color-accent-2, transparent),
  3: var(--color-accent-3, transparent),
);

// Neutral
$color-neutral: var(--color-neutral, #FFFFFF);
$color-neutral-2: #FFFFFF; // don't exist on the backend, just here for the elements which are always white
