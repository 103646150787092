@import "colors";

.mat-expansion-panel {
  .mat-expansion-indicator svg {
    fill: rgba(var(--color-neutral-rgb), 0.5);
  }

  .mat-expansion-indicator::after {
    color: rgba(var(--color-neutral-rgb), 0.5);
  }

  .mat-expansion-panel-body {
    padding: 0 16px 16px;
  }

  .mat-content.mat-content-hide-toggle {
    margin: 0;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    color: $color-neutral;
  }
}




