@import 'colors';
.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
